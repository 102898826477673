
a {
    color: inherit!important;
    text-decoration: none!important;
  }
  
  h1 {
    font-family: 'Manrope'!important;
    font-style: normal!important;
    font-weight: 700!important;
  }
  
  h2 {
    font-family: 'Manrope'!important;
    font-style: normal!important;
    font-weight: 700!important;
  }
  
  h3 {
    font-family: 'Manrope'!important;
    font-style: normal!important;
    font-weight: 700!important;
  }
  
  h4 {
    font-family: 'Manrope'!important;
    font-style: normal!important;
    font-weight: 700!important;
  }
  
  h5 {
    font-family: 'Manrope'!important;
    font-style: normal!important;
    font-weight: 700!important;
  }
  h6 {
    font-family: 'Manrope'!important;
    font-style: normal!important;
    font-weight: 700!important;
  }

  ol, ul {
    margin: unset!important;
    padding-left: 40px!important;
}

table#tableMatching {
  width: 100%;
  border-collapse: collapse!important;
}
table#tableMatching>tr>th, table#tableMatching>tr>td {
  /* border: 1px solid #000; */
  padding: 8px;
  text-align: left;
}
table#tableMatching>tr>th {
  background-color: rgb(0 134 248 );
  color: white;
}
